import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: { name: "SelectLanguage" } },
  {
    path: "/select-language",
    name: "SelectLanguage",
    component: () =>
      import(
        /* webpackChunkName: "select-language" */ "../views/SelectLanguageView.vue"
      ),
  },
  {
    path: "/select-avatar",
    name: "SelectAvatar",
    component: () =>
      import(
        /* webpackChunkName: "select-avatar" */ "../views/SelectAvatarView.vue"
      ),
  },
  {
    path: "/main-menu",
    name: "MainMenu",
    component: () =>
      import(/* webpackChunkName: "main-menu" */ "../views/MainMenuView.vue"),
  },


  {
    path: "/testing",
    name: "Testing",
    component: () =>
      import(/* webpackChunkName: "testing" */ "../views/TestingView.vue"),
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
