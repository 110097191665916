import i18n from "@/i18n";

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    baseURL: "https://library.vidarstudio.com:2096",

    ready: false,
    floorsInfo: {
      en: [],
      cn: [],
      zh: [],
    },
  },
  getters: {
    baseURL: ( state ) => {
      return state.baseURL;
    },
    floorsInfoWithLang: ( state ) => {
        if( state.ready ){
          const langCode = i18n.locale;
          // console.log( "= = floor ready = =", state.floorsInfo[ langCode ] );
          return state.floorsInfo[ langCode ];
        } else {
          return [];
        }
    },
    // sliderFloorsInfoWithLang: ( state, getters ) => {
    //   // console.log( "s" , getters , getters.floorsInfoWithLang );
    //   const hideItem
    //   return getters.floorsInfoWithLang.filter( f => );
    // },
  },
  actions: {
    loadFromBackend( context ) {
      const axios = require("axios").default;
      const axiosInstance = axios.create({
        baseURL: context.state.baseURL,
        headers: {
          Authorization: "Bearer test",
        },
      });

      const langCodes = ["zh", "en", "cn"];

      // --- get floors data for all lang ---
      Promise.all(
        langCodes.map(function (lang) {
          return axiosInstance.post("api/presenter/floor/list", { lang: lang });
        })
      )
        .then(function (receives) {
          for (var i = 0; i < receives.length; i++) {
            if (receives[i]) {
              context.state.floorsInfo[langCodes[i]] =
                receives[i].data.floor_list;
            }
          }

          // --- facility ---
          Promise.all(
            langCodes.map(function (lang) {
              return axiosInstance.post("api/presenter/facility/list", { lang: lang });
            })
          ).then(function (receives2) {
            for (var i = 0; i < receives2.length; i++) {
              if (receives2[i]) {
                context.state.floorsInfo[langCodes[i]].forEach(function (
                  floor
                ) {
                  floor.facilities =
                    receives2[i].data.facility_list[floor.name];
                });
              }
            }


            context.state.ready = true;
            // console.log( context.state.floorsInfo );
          });
        })
        .catch(function (err) {
          alert("server error");
          console.error(err);
        });
    },
  },
});
